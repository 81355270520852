export default ({ color = '#6d8a9d' }) => (
  <svg
    width='18px'
    height='21px'
    viewBox='0 0 18 21'
    color={color}
    className='bell-icon'
  >
    <g stroke='none' strokeWidth='1' fill='currentColor' fillRule='evenodd'>
      <g>
        <path
          d='M8.92265625,0.07445925 C8.5933989,0.112419 8.3194002,0.42509925 8.325,0.7564905 L8.325,1.473678 C7.0708023,1.6301943 5.99669663,2.2166685 5.1890625,3.048678 C4.23996097,4.0264245 3.64074345,5.32771425 3.3890625,6.71195925 L2.4890625,11.6619593 C2.30230395,12.6891293 1.92157965,12.8999205 1.378125,13.258053 C1.10639767,13.437108 0.7750206,13.6281443 0.48515625,13.9682093 C0.1952919,14.3082968 0,14.8049955 0,15.3814905 C0,16.367013 0.8144757,17.1814905 1.8,17.1814905 L5.63203125,17.1814905 C5.7488859,18.9351736 7.21773585,20.3314905 9,20.3314905 C10.7822642,20.3314905 12.2511141,18.9351736 12.3679688,17.1814905 L16.2,17.1814905 C17.1855243,17.1814905 18,16.367013 18,15.3814905 C18,14.8049955 17.8047083,14.3082743 17.5148438,13.9682093 C17.2249792,13.6281443 16.8936021,13.437108 16.621875,13.258053 C16.078421,12.8999205 15.6976963,12.6891518 15.5109375,11.6619593 L14.6109375,6.71195925 C14.3592565,5.32771425 13.7600388,4.0264245 12.8109375,3.048678 C12.0033036,2.2166685 10.9291977,1.6301943 9.675,1.473678 L9.675,0.7564905 C9.68147775,0.373428 9.30324803,0.03054825 8.92265625,0.07445925 Z'
          fill='#9FB8C9'
          fillRule='nonzero'
        ></path>
        <path d='M9,2.7814905 C10.1845712,2.7814905 11.112516,3.2337495 11.840625,3.98383425 C12.568734,4.733919 13.0791665,5.796594 13.2890625,6.95102175 L14.1890625,11.9010218 C14.4445993,13.3064843 15.3001217,13.9985505 15.8835937,14.383053 C16.1753299,14.575293 16.3831921,14.7168068 16.4882812,14.8400843 C16.5933704,14.9633843 16.65,15.0595155 16.65,15.3814905 C16.65,15.642468 16.4609757,15.8314905 16.2,15.8314905 L1.8,15.8314905 C1.5390243,15.8314905 1.35,15.642468 1.35,15.3814905 C1.35,15.0595155 1.40662935,14.9633843 1.51171875,14.8400843 C1.61680815,14.7168068 1.82467057,14.575293 2.11640625,14.383053 C2.6998776,13.9985505 3.5554005,13.3064843 3.8109375,11.9010218 L4.7109375,6.95102175 C4.92083347,5.796594 5.431266,4.733919 6.159375,3.98383425 C6.887484,3.2337495 7.81542877,2.7814905 9,2.7814905 Z'></path>
        <path d='M7.0171875,17.1814905 L10.9828125,17.1814905 C10.8686853,18.200043 10.0533843,18.9814905 9,18.9814905 C7.9466157,18.9814905 7.1313147,18.200043 7.0171875,17.1814905 Z'></path>
      </g>
    </g>
  </svg>
);
